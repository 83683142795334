<template>
  <AppLayout wrapperClass="paymentInfo">
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="12">
            <h1>{{ $t("payment_success") }}</h1>
            <div v-if="transactionId != null">
              {{ $t("transaction") }}: {{ "#" + transactionId }}
            </div>
            <cmsBlockSimple identifier="checkout_succes" />
          </b-col>
        </b-row>
      </b-container>
    </section>
  </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout"
import { getOrderInfoFromHash } from "@storefront/core/data-resolver/cart";
import config from "@storefront/core/lib/utils/config";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

export default {
  name: "PaymentSuccess",
  components: {
    AppLayout,
    cmsBlockSimple,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_success") },
        { name: "keywords", content: this.$t("payment_success") },
        { name: "description", content: this.$t("payment_success") },
      ],
      title: this.$t("payment_success"),
    };
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
  },
  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);

    const hashId = localStorage.getItem("orderHash");

    if (hashId) {
      const order = await getOrderInfoFromHash(hashId);

      if (order) {
        this.orderId = order.number;
        if (this.$gtm.enabled() == true) {
          const items = [];
          const prods = [];
          order.items.forEach((element) => {
            let item = {};
            let prod = {};
            item.id = element.product_sku;
            item.name = element.product_name;
            item.price = element.product_sale_price;
            item.quantity = element.quantity_ordered;
            prod.item_id = element.product_sku;
            prod.item_name = element.product_name;
            prod.price = element.product_sale_price;
            prod.quantity = element.quantity_ordered;
            items.push(item);
            prods.push(prod);
          });

          if (!this.$gtm.uaDisabled) {
            //GA event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                currencyCode: "EUR",
                purchase: {
                  actionField: {
                    id: order.number,
                    affiliation: config.app_name,
                    revenue: order.total.subtotal.value,
                    tax: order.total.total_tax.value,
                    shipping: order.total.total_shipping.value,
                  },
                  products: items,
                },
              },
            });
          }
          if (this.$gtm.ga4Enabled) {
            //GA4 event
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                add: {
                  transaction_id: order.number,
                  affiliation: config.app_name,
                  value: order.total.subtotal.value,
                  tax: order.total.total_tax.value,
                  shipping: order.total.total_shipping.value,
                  items: prods,
                },
              },
            });
          }
        }

        if (config.helloRetail && config.helloRetail.enabled) {
          const hritems = [];
          order.items.forEach((element) => {
            let item = {};
            item.id = element.product_sku;
            hritems.push(item);
          });

          self.ADDWISH_PARTNER_NS.api.conversion.track_sale(
            {
              total: order.total.subtotal.value,
              orderNumber: order.number,
              productNumbers: hritems,
            },
            function () {
              // Conversion has been tracked for HR
            }
          );
        }
      }
    }
  },

  data: () => ({}),
};
</script>
